<template>
  <b-card
    v-if="Object.keys(account).length !== 0"
    class="user-toolbar"
  >
    <div class="d-flex align-items-center">
      <!-- <feather-icon
        icon="UserIcon"
        size="24"
      /> -->
      <b-avatar
        size="42"
        :text="avatarText(account.name)"
        :variant="account.roles.includes('admin') ? 'light-danger' : 'light-primary'"
      />
      <h4>
        {{ account.name }}
        <small>{{ account.email }}</small>
      </h4>
    </div>
    <b-button
      v-if="hideUserMenu"
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="outline-primary"
      class="btn-icon"
      @click="userMobileMenuOpen = !userMobileMenuOpen"
    >
      <feather-icon
        :icon="userMobileMenuOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
        size="16"
      />
    </b-button>
    <b-button-group
      class="button-group-custom"
      :class="{'close': hideUserMenu && !userMobileMenuOpen}"
    >
      <b-button
        v-if="authUserData.role.includes('admin') && (storeAccount && storeAccount.owns !=='')"
        v-b-tooltip.hover.top="$t('Pixels')"
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="outline-primary"
        class="btn-icon"
        :to="{ path: `/users/${$route.params.userId}/pixels`}"
      >
        <span>{{ $t('Pixels') }}</span>
        <b-badge
          class="badge-balance ml-25"
          variant="primary"
        >
          {{ storeAccount ? storeAccount.owns.pixels : pixels.length }}
        </b-badge>
      </b-button>
      <b-button
        v-if="(authUserData.role.includes('admin') || account.is_agency) && (storeAccount && storeAccount.owns !=='')"
        v-b-tooltip.hover.top="$t('page.partners')"
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="outline-primary"
        class="btn-icon"
        :to="{ name: 'dashboard-user-agencies' }"
      >
        <span>{{ $t('page.partners') }}</span>
        <b-badge
          class="badge-balance ml-25"
          variant="primary"
        >
          {{ storeAccount ? storeAccount.owns.agencies : agencies.length }}
        </b-badge>
      </b-button>
      <b-button
        v-b-tooltip.hover.top="$t('sites.sites')"
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="outline-primary"
        class="btn-icon"
        :to="{ name: 'dashboard-user-toolbar-sites' }"
      >
        <span>{{ $t('sites.sites') }}</span>
        <b-badge
          class="badge-balance ml-25"
          variant="primary"
        >
          {{ account.owns.sites || 0 }}
        </b-badge>
      </b-button>
      <b-button
        v-b-tooltip.hover.top="$t('Campaigns')"
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="outline-primary"
        class="btn-icon"
        :to="{ name: 'dashboard-user-toolbar-campaigns' }"
      >
        <span>{{ $t('menu.campaigns') }}</span>
        <b-badge
          class="badge-balance ml-25"
          variant="primary"
        >
          {{ account.owns.campaigns || 0 }}
        </b-badge>
      </b-button>
      <b-button
        v-b-tooltip.hover.top="$t('profile.balance')"
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="outline-primary"
        class="btn-icon"
        :to="{ name: 'dashboard-users-payments' }"
      >
        <span>{{ $t('profile.balance') }}</span>
        <b-badge
          class="badge-balance ml-25"
          variant="primary"
        >
          {{ currencyFormatter(account.currency, account.balance) }}
        </b-badge>
      </b-button>
      <b-button
        v-b-tooltip.hover.top="$t('Settings')"
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="outline-primary"
        class="btn-icon"
        :to="{ name: 'dashboard-users-edit' }"
      >
        <feather-icon
          icon="SettingsIcon"
          size="16"
        />
        {{ $t('menu.profile') }}
      </b-button>
    </b-button-group>
  </b-card>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import currencyFormatter from '@/utils/currency-formatter'
import { getUserData } from '@/auth/utils'
import {
  BCard,
  BButton,
  BAvatar,
  BButtonGroup,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BAvatar,
    BButton,
    BButtonGroup,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currencyFormatter,
      authUserData: getUserData(),
      pixels: [],
      agencies: [],
      userMobileMenuOpen: false,
    }
  },
  computed: {
    hideUserMenu() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      if (currentBreakPoint === 'sm' || currentBreakPoint === 'xs') {
        return true
      }
      return false
    },
    storeAccount() {
      return this.$store.getters['dashboard-user-toolbar/account'](this.$route.params.userId)
    },
  },
  async created() {
    if (!this.storeAccount) {
      this.getStorageAccountData()
      if (this.authUserData.role.includes('admin')) {
        // this.fethPixels()
        // this.fethAgencies()
      }
    }
  },
  methods: {
    async getStorageAccountData() {
      const { userId } = this.$route.params
      await this.$store.dispatch('dashboard-user-toolbar/getAccount', userId)
    },
    async fethPixels() {
      if (this.$route.params.userId) {
        const responseData = await useJwt.accountPixels(this.$route.params.userId)
        this.pixels = responseData.data.pixels || []
      }
    },
    async fethAgencies() {
      if (this.$route.params.userId) {
        const responseData = await useJwt.accountAgencies(this.$route.params.userId)
        this.agencies = responseData.data.agencies || []
      }
    },
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
  },
}
</script>

<style scoped>
.badge-balance {
  margin-bottom: -5px;
}
</style>
